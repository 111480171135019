const svg = {
  circlek: (
    <g id="Layer_1-2_1_">
      <path
        class="st0"
        d="M31.2,24.1l9.5-13h-9.8l-8.8,12.5V11h-8.5v27.1h8.5V25.7l15.2,19.8c-3.9,2.4-8.5,3.7-13.1,3.7
			C10.5,49.2,0,38.5,0,24.6C0,10.6,10.5,0,24.3,0s24.3,10.6,24.3,24.6c0,5.5-1.7,10.8-5,15.2L31.2,24.1z"
      />
    </g>
  ),

  posters: (
    <g id="Layer_1-2_1_">
      <path
        d="M17.8,0v50.4H0l0.2,1.2c0,0.2,1.2,6.1,6.6,8.2c1.3,0.5,2.7,0.8,4.1,0.8c20.9,0.1,36.7,0,43.7,0c1.5,0,4.8,0,7.2-2.3
	c2.2-2,2.7-4.9,2.8-6.6V0H17.8z M35.4,35.3l0.8-1.1l5.2-7.3l5.6-7.8l11.7,16.3H35.4z M35.8,19.4l3.5,4.6l0.9,1.1l-6.4,9h-9.1
	L35.8,19.4z M11.1,58.6c-1.2,0-2.5-0.2-3.5-0.6c-3.1-1.2-4.5-4-5.1-5.5h15.3l0,0.1h27.9c0.3,1.1,1.2,4.1,3.9,6.1H11.1z"
      />
    </g>
  ),
  logo: (
    <g>
      <path
        d="M0.2,8.5c0,0,0-0.1,0-0.1V0.2c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0h1.1c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v7c0,0,0,0.1,0.1,0.1
		h3.3c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.9c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0H0.3C0.3,8.5,0.3,8.5,0.2,8.5z"
      />
      <path
        d="M7.7,17.2C7.3,17,7,16.7,6.8,16.3c-0.2-0.4-0.3-0.9-0.3-1.4V2.6c0-0.5,0.1-1,0.3-1.4C7,0.8,7.3,0.5,7.7,0.3
		C8.1,0.1,8.6,0,9.1,0c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.3,0.9,0.3,1.4v12.3c0,0.5-0.1,1-0.3,1.4
		c-0.2,0.4-0.5,0.7-0.9,0.9c-0.4,0.2-0.9,0.3-1.4,0.3C8.6,17.6,8.1,17.5,7.7,17.2z M10,16c0.2-0.3,0.4-0.6,0.4-1V2.6
		c0-0.4-0.1-0.7-0.4-1S9.5,1.2,9.1,1.2c-0.4,0-0.7,0.1-0.9,0.4C8,1.8,7.8,2.2,7.8,2.6V15c0,0.4,0.1,0.7,0.3,1
		c0.2,0.3,0.5,0.4,0.9,0.4C9.5,16.4,9.8,16.2,10,16z"
      />
      <path
        d="M1.3,17.2c-0.4-0.2-0.7-0.5-0.9-0.9C0.1,16,0,15.5,0,15.1v-3.6c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9S2.1,9,2.6,9
		s1,0.1,1.4,0.3c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.3,0.8,0.3,1.3v0.3c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0H4c0,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1v-0.3c0-0.4-0.1-0.7-0.3-0.9S3,10.2,2.6,10.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.5-0.3,0.9v3.6
		c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.9c0,0,0-0.1-0.1-0.1H2.7
		c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.9c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0h2.4c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1V15
		c0,0.8-0.2,1.4-0.7,1.9c-0.5,0.5-1.1,0.7-1.9,0.7C2.1,17.6,1.6,17.5,1.3,17.2z"
      />
    </g>
  ),
  biedronka: (
    <g>
      <path
        class="st0"
        d="M34.3,35.9c0,9.5-7.7,17.1-17.2,17.1c-2.9,0-5.7-0.7-8.2-2.1l-0.3-0.1l0-0.3c-0.2-1.3-0.3-2.7-0.2-4.1
					c-0.6-0.4-1.1-0.8-1.6-1.3c-0.3,1.1-0.5,2.1-0.7,2.7l-0.2,1l-0.7-0.7C-1.6,41.6-1.7,30.7,4.9,24c2.9-3,6.8-4.8,10.9-5.1
					c0.1,0.4,0.2,0.8,0.4,1.1c-8.8,0.5-15.5,8.1-15,17c0.1,1.5,0.4,3.1,1,4.5c0.6,1.8,1.6,3.4,2.8,4.8l0.3,0.3l0.1-0.4
					c0.4-1.6,1.1-4.1,2-6.8c2.1-6.3,5.6-12.1,10.2-17c0.4,0.5,0.8,1,1.3,1.5v0c-3,3.2-5.4,7-7,11.1c-1.7,4.3-2.4,8.9-2.1,13.5v0
					c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4l0,0.1l0,0l0.1,0
					c0.8,0.4,1.7,0.8,2.5,1c5.7,1.8,11.9,0.3,16.1-3.9c0.2-0.2,0.4-0.4,0.5-0.6c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.4
					c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0.1-0.1,0.1-0.2
					c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.5c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.6
					c0,0,0,0,0,0c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2
					c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.5
					c0-0.1,0-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0h0
					c0-0.5,0.1-1,0.1-1.5c0-2.6-0.6-5.1-1.8-7.4c0,0,0,0,0,0c-0.2-0.4-0.4-0.7-0.6-1.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.3
					s-0.2-0.2-0.2-0.3L30,26.5c0.4-0.1,0.8-0.3,1.1-0.4C33.2,28.9,34.3,32.4,34.3,35.9z"
      />
      <path
        class="st0"
        d="M33,38.2c0,0.1,0,0.2,0,0.2C32.9,38.4,32.9,38.3,33,38.2z"
      />
      <path
        class="st0"
        d="M32.5,40.4c0,0.1-0.1,0.2-0.1,0.3C32.4,40.6,32.5,40.5,32.5,40.4z"
      />
      <path
        class="st0"
        d="M32.2,41.1c0,0.1-0.1,0.2-0.1,0.2C32.2,41.3,32.2,41.2,32.2,41.1z"
      />
      <path
        class="st0"
        d="M32.7,39.7c0,0.1,0,0.2-0.1,0.3C32.6,39.9,32.7,39.8,32.7,39.7z"
      />
      <path
        class="st0"
        d="M30.6,27.4c-0.1-0.2-0.3-0.5-0.5-0.7C30.3,26.9,30.5,27.1,30.6,27.4z"
      />
      <path
        class="st0"
        d="M30.2,26.7c0-0.1-0.1-0.1-0.1-0.2h0C30.1,26.5,30.1,26.6,30.2,26.7z"
      />
      <path
        class="st0"
        d="M30.6,27.4c0.2,0.4,0.4,0.7,0.6,1.1C31.1,28.1,30.9,27.7,30.6,27.4z"
      />
      <path class="st0" d="M33,37.5c0,0,0,0.1,0,0.1C33,37.6,33,37.5,33,37.5z" />
      <path
        class="st0"
        d="M32.8,39c0,0.1,0,0.2-0.1,0.3C32.8,39.1,32.8,39,32.8,39z"
      />
      <path
        class="st0"
        d="M9.7,49.6c0-0.1,0-0.1,0-0.2C9.7,49.5,9.7,49.5,9.7,49.6z"
      />
      <path
        class="st0"
        d="M29.1,46.5c0,0-0.1,0.1-0.1,0.1C29,46.6,29,46.6,29.1,46.5z"
      />
      <polygon class="st0" points="9.8,50.1 9.8,50.1 9.8,50.1 				" />
      <path
        class="st0"
        d="M9.7,49.1c0-0.1,0-0.2,0-0.4C9.6,48.9,9.7,49,9.7,49.1z"
      />
      <path
        class="st0"
        d="M12.4,51.2c4,1.3,8.4,0.9,12.1-1.1c-2.3,1.2-4.8,1.8-7.3,1.8C15.5,51.9,13.9,51.6,12.4,51.2z"
      />
      <path
        class="st0"
        d="M30.5,44.7c0,0.1-0.1,0.1-0.1,0.2C30.4,44.8,30.5,44.7,30.5,44.7z"
      />
      <path
        class="st0"
        d="M32,41.9c0,0.1,0,0.1-0.1,0.2C31.9,42,31.9,41.9,32,41.9z"
      />
      <path
        class="st0"
        d="M29.6,45.9c-0.1,0.1-0.1,0.1-0.2,0.2C29.5,46,29.6,46,29.6,45.9z"
      />
      <path
        class="st0"
        d="M30.9,44c0,0.1-0.1,0.1-0.1,0.2C30.8,44.2,30.9,44.1,30.9,44z"
      />
      <path
        class="st0"
        d="M31.3,43.4c0,0.1-0.1,0.1-0.1,0.2C31.2,43.5,31.2,43.5,31.3,43.4z"
      />
      <path class="st0" d="M31.6,42.7L31.6,42.7L31.6,42.7z" />
      <path
        class="st0"
        d="M30.1,45.3c-0.1,0.1-0.1,0.1-0.2,0.2C30,45.4,30,45.4,30.1,45.3z"
      />
      <path
        class="st0"
        d="M21.8,35.4c0,2-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7c0-2,1.7-3.7,3.7-3.7C20.2,31.7,21.8,33.4,21.8,35.4
					C21.8,35.4,21.8,35.4,21.8,35.4z"
      />
      <path
        class="st0"
        d="M23.2,46.2c0,0.3-0.1,0.7-0.2,1c-0.7,1.2-2.1,1.9-3.5,1.8c-0.5,0-1.1-0.1-1.6-0.3c-1.2-0.3-2-1.3-2.1-2.5
					c0-1.5,1.7-2.8,3.7-2.8S23.2,44.7,23.2,46.2z"
      />
      <path
        class="st0"
        d="M32.1,39.2c0,0.8-0.3,1.6-0.6,2.3c-0.8,1.7-2.2,2.8-3,2.4c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.6-0.6-2.1,0.2-3.7
					c0.8-1.7,2.1-2.8,3-2.4C31.9,37.8,32.1,38.4,32.1,39.2z"
      />
      <path
        class="st0"
        d="M9.7,30.8c-1.3,1.5-3.2,1.9-4.4,0.9c-0.3-0.3-0.5-0.6-0.6-1c-0.3-1.2,0-2.6,0.9-3.5
					c0.7-0.9,1.8-1.4,2.9-1.5c0.5,0,1.1,0.2,1.5,0.5C11,27.3,10.9,29.3,9.7,30.8z"
      />
      <path
        class="st0"
        d="M11.1,23.7c-1.3,0.5-2.5,0.6-2.8,0.1c0,0,0,0,0-0.1c-0.2-0.6,0.7-1.5,2-2c1.2-0.5,2.3-0.6,2.7-0.2
					c0.1,0,0.1,0.1,0.1,0.2C13.3,22.2,12.4,23.1,11.1,23.7z"
      />
      <path
        class="st0"
        d="M2.6,40.3c-0.6,0-1.2-1.3-1.2-3.1c-0.1-1.7,0.3-3.2,0.9-3.2h0c0.3,0,0.6,0.4,0.8,0.9
					c0.3,0.7,0.4,1.4,0.4,2.2C3.6,38.8,3.2,40.2,2.6,40.3z"
      />
      <path
        class="st0"
        d="M28.6,32.6c-1,0.2-2.1-0.7-2.4-2.1c-0.1-0.5-0.1-1.1,0-1.6c0.1-0.6,0.6-1.1,1.2-1.3
					c0.9-0.1,1.8,0.4,2.1,1.3c0.1,0.2,0.2,0.5,0.3,0.8C30.2,31.1,29.6,32.4,28.6,32.6z"
      />
      <path
        class="st0"
        d="M26.6,4.8c-0.7,0-1.4,0.1-2.1,0.2c0.1-0.8,0.5-1.7,1-2.4c0.6-0.8,1.5-1.3,2.4-1.5c0,0.1,0,0.1,0.1,0.2
					c0.2,0.4,0.7,0.6,1.2,0.4s0.6-0.7,0.4-1.2l0,0c-0.2-0.4-0.7-0.6-1.2-0.4c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.5,0.5
					c-1.4,0.2-2.7,0.9-3.5,2.1c-0.6,0.7-1,1.6-1.1,2.6c-1.6,0.5-3.1,1.4-4.4,2.5c-0.4-0.6-0.9-1.2-1.4-1.7c-0.8-0.8-1.7-1.4-2.8-1.7
					c0,0,0-0.1,0-0.1c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0.1-0.8,0.5-0.8,1C13,5,13.5,5.3,14,5.3c0.2,0,0.4-0.1,0.6-0.3
					c0.9,0.4,1.6,1,2.2,1.8c0.5,0.6,1,1.2,1.3,1.9c-2.4,2.8-3.3,6.6-2.3,10.2c0.1,0.4,0.2,0.8,0.4,1.1c0.1,0.2,0.2,0.5,0.3,0.7
					c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.3,0.4,0.6,0.6,0.9c0.4,0.5,0.8,1,1.3,1.5c3,2.9,7.3,3.9,11.3,2.6h0c0.4-0.1,0.8-0.3,1.1-0.4
					c5.6-2.5,8.1-9.1,5.5-14.8C34.9,7.3,30.9,4.8,26.6,4.8z M26.5,25.9c-5.5,0-9.9-4.4-9.9-9.9c0-5.5,4.4-9.9,9.9-9.9
					c5.5,0,9.9,4.4,9.9,9.9C36.5,21.4,32,25.9,26.5,25.9C26.5,25.9,26.5,25.9,26.5,25.9z"
      />
      <path
        class="st0"
        d="M33.3,18.4c-0.3-0.7-1.2-0.9-1.8-0.6c-0.5,0.2-0.7,0.7-0.7,1.2c0.3,0.7,1.2,0.9,1.8,0.6
					C33,19.4,33.3,18.9,33.3,18.4z M32.3,19.3c-0.6,0.1-1.1-0.1-1.2-0.4c-0.1-0.4,0.3-0.8,0.8-1c0.6-0.1,1.1,0.1,1.2,0.4
					S32.8,19.2,32.3,19.3z"
      />
      <path
        class="st0"
        d="M33.1,18.4c-0.1-0.4-0.6-0.6-1.2-0.4c-0.6,0.1-0.9,0.6-0.8,1c0.1,0.4,0.6,0.6,1.2,0.4
					C32.8,19.2,33.2,18.8,33.1,18.4z M31.9,18.7c-0.2,0.1-0.4,0-0.4-0.2c0-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.3,0,0.4,0.1
					c0.1,0.1,0,0.3-0.1,0.4C32,18.7,31.9,18.7,31.9,18.7z"
      />
      <path
        class="st0"
        d="M27,21.1c-2-0.1-3.9-0.9-5.5-2.1c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.5-0.1,0.7-0.2c-0.6-0.1-1.2,0-1.8,0.3
					c-0.4,0.3-0.6,0.8-0.6,1.4c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.2-0.2,0.4-0.3c0,0,0.9,4.3,4.9,5c5.9,1,7.1-4,7.1-4l0.7-0.6
					C31.5,20.6,29.2,21,27,21.1z M24.3,23.5c-0.8-0.4-1.4-1.1-1.7-1.9c0,0,1.8-0.1,2.3,0.6c0.2,0.3,0.5,0.6,0.7,1
					c0-0.1-0.2-0.6-0.2-0.6c0.5,0,1,0.1,1.3,0.4c0.2,0.3,0.4,0.7,0.5,1.1C26.2,24.1,25.2,23.9,24.3,23.5L24.3,23.5z"
      />
      <path
        class="st0"
        d="M26.2,17.6c-0.6,0.2-1.6-0.8-2.1-2.3c-0.2-0.6-0.3-1.3-0.3-1.9l0.9,0.1c0,0-0.4-0.6-0.6-1
					c0.1-0.1,0.2-0.2,0.3-0.2c0.6-0.2,1.6,0.8,2.1,2.3S26.8,17.4,26.2,17.6z"
      />
      <path
        class="st0"
        d="M33.3,15.3c-0.5,0.2-1.3-0.7-1.9-2c-0.2-0.5-0.4-1-0.5-1.5l0.7,0.1c0,0-0.4-0.7-0.7-1.1
					c0-0.1,0.1-0.1,0.2-0.2c0.5-0.2,1.3,0.7,1.9,2C33.6,13.9,33.7,15.1,33.3,15.3z"
      />
    </g>
  ),
  kaufland: (
    <g>
      <path
        class="st0"
        d="M43.8,48.1H0V0h43.8V48.1z M1.1,47h41.6V1.1H1.1V47z"
      />
      <path id="path2924_8_" class="st1" d="M3.3,25h16v19.8h-16V25z" />
      <path id="path2926_8_" class="st1" d="M3.3,3.2h16V23h-16V3.2z" />
      <path id="path2928_8_" class="st1" d="M21.5,3.2h19.1L21.5,23V3.2z" />
      <path id="path2930_8_" class="st1" d="M21.5,25l19.1,19.8H21.5V25z" />
    </g>
  ),
  rimi: (
    <g>
      <path
        class="st0"
        d="M2.7,2.1c0,0,8.1-2.8,13.4-1.9c5.2,0.9,7.9,5,5.3,14.2C18.7,23.5,9.9,41.2,0,48.1C0,48.1,15.9,13.9,2.7,2.1
        "
      />
      <path
        class="st0"
        d="M23.5,5.9c0,0,7.3-2.1,11.6-0.9c4.4,1.1,4.9,4.7,4.1,7.6c-0.8,2.9-6.8,20.9-30.5,31.1
        C8.7,43.7,33.5,13,23.5,5.9"
      />
    </g>
  ),
  spar: (
    <g id="Spar_8_">
      <path
        class="st0"
        d="M11.6,29.8c-0.7,1-1.5,1.9-2.2,2.8l13.6,0c-0.1,1.8,0.3,4-0.5,5.5c-1,1.6-2.8,2.6-4.7,2.6
        c-5.5-0.5-9.1-5.3-11.2-9.8c-0.2-0.4-0.3-0.7-0.4-1.1C4.3,24.4,5.3,17.4,8.9,13c5.5-7,14.9-9.9,23.4-7.1c5.5,1.8,10,5.8,12.4,11
        c1.6,4.1,1.8,8.7,0.4,13c-0.8,2.7-2.3,5.2-4.3,7.2c-2.5,2.1-5.1,4.2-8.5,3.6c-1.8-0.4-3.2-1.7-3.7-3.5c-0.3-1.5-0.4-3-0.1-4.6
        l13.8,0l-0.4-0.5c-0.6-0.8-1.3-1.5-1.9-2.3h0C33.9,22.3,29.1,14,25.6,5C22,13.8,17.3,22.2,11.6,29.8L11.6,29.8z M0.8,29.8
        c2.3,8.2,8.6,14.6,16.8,17c10.2,3.1,21.3,0,28.3-8c2.1-2.6,3.7-5.7,4.5-9c1.5-5.6,0.8-11.6-1.9-16.7c-2.1-3.6-4.9-6.7-8.4-9
        c-7.6-5-18.9-5.4-26.8-1.2C5.1,7-0.5,15.7,0,25.2C0.1,26.7,0.4,28.3,0.8,29.8"
      />
    </g>
  ),
  wiko: (
    <g id="Wiko_logo_8_">
      <path
        class="st0"
        d="M67.4,6.8c-1.8,6.3-4.5,16.8-7.3,26c-1.5,5-3.2,10.6-4.3,13.1c-1.9,4.2-6.8,6-11,4.1
					c-1.8-0.8-3.3-2.3-4.1-4.1c-1.3-3.3-4.2-12.6-5.3-16c-0.4-1.3-1.7-2.2-3.1-2.1c-1.4-0.1-2.6,0.8-3.1,2.1
					c-1.2,3.4-3.2,12.6-4.5,16c-1.1,2.9-3.9,4.9-7,4.9c-3.1-0.1-5.8-2-6.8-4.9C10.2,44.3,1,21.5,0.6,20.4C0.3,19.6,0.1,18.8,0,18
					c0-2.8,2.2-5,4.9-5c0,0,0,0,0,0c2.2,0,4.1,1.4,4.7,3.5c1.1,4.1,4.4,18.1,4.8,19.5c0.4,1.8,2.2,2.9,4,2.4
					c1.2-0.3,2.1-1.2,2.4-2.4c-0.1,0.3,5.5-21.6,6.6-25C28.1,9,30,7.5,32.1,7.5c2.2,0,4.1,1.5,4.7,3.5l0,0c0.7,1.9,5.8,19.4,7.4,25
					c0.4,1.5,1.7,2.5,3.2,2.5c1.5,0,2.8-1.1,3.2-2.5c1.9-7.8,7-31.8,7-31.8c0.4-2.7,2.9-4.6,5.6-4.2c2.5,0.4,4.3,2.5,4.2,5
					C67.6,5.6,67.6,6.2,67.4,6.8"
      />
    </g>
  ),
  netto: (
    <g id="Layer_1-2_8_">
      <path
        class="st0"
        d="M45,40.8L17,0.2l-1-0.2l-0.1,4.4l0,0c-0.5-0.4-1-0.9-1.5-1.4c-0.3-0.3-0.6-0.7-0.9-1l-0.1,0H13
				c-0.3,0-0.4,0.3-0.4,0.6l0.6,3.1c0,0.2,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0,1.1c-0.4,0.6-0.8,0.4-1.4,0c-0.3-0.3-0.7-0.4-1.2-0.5
				l-0.1,0c-0.2,0.1-0.3,0.2-0.3,0.3c0,0.2,0.1,0.5,0.2,0.7c0.1,0.1,0.1,0.2,0.2,0.3C9.3,7.3,8.4,7,7.5,7.2l-0.1,0v0.1
				c0.1,0.4,0.3,0.8,0.7,1c0.1,0.1,0.2,0.2,0.3,0.3C7.7,8.3,7,8.1,6.3,8.1c0,0-0.1,0-0.2,0C5.9,8,5.3,8,5.2,8.6v0.1l0,0.1
				C5.5,8.9,5.7,9.1,6,9.2c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.3,0,0.3,0,0.3c-2.3,0.7-4.2,1.4-6.1,2.1c-0.5,0.4-0.7,1-0.6,1.6
				c0.2,0.6,0.4,1.2,0.5,1.7c0.9,2.3,1.3,4.8,1.2,7.3c0,0.2,0,0.3,0.2,0.4c0.2,0.1,0.4,0.1,0.6,0l1.8-2.1c0.1,0.1,0.1,0.3,0,0.6
				c0,0.2,0,0.4,0,0.5v0.1c0,0.3,0,0.8,0.2,1c0.1,0.1,0.3,0.1,0.4,0l0.1,0c0.1-0.2,0.3-0.3,0.4-0.5c0.3-0.3,0.5-0.6,0.8-0.9v0.6
				c0,0.5,0,1,0,1.5L0.7,24v0.2c0,1.6,0,3.2,0,4.9c0,1.8,0,3.6,0,5.6l0.2,1.2l0,0c0.4,0.7,1,1.3,1.8,1.5l10.2,0l1.2-0.4l0.1,0
				c0.6-0.4,1-1.1,1.1-1.8l0-11.2l-5.8-0.1c0-0.4,0-0.8,0-1.1c-0.3-0.9,0.1-1.9,0.8-2.4c0.1-0.1,0.2-0.1,0.2-0.1c0,0.1,0,0.3,0,0.4
				c0,0.4,0,0.9,0.4,0.9H11l0,0c0.5-0.4,1-0.8,1.3-1.4c0.6-0.8,1.2-1.6,2.2-1.6c0.6,0.1,1.1,0.5,1.3,1.1l0.1,18l-0.3,1.5
				c-0.4,0.9-1,1.7-1.7,2.4c-0.2,0.2-0.4,0.4-0.6,0.7l0,0.1c-0.2,0.6-0.2,1.3,0,1.9c0.3,0.6,0.9,1.1,1.5,1.3l27.4,0.1
				c0.9-0.1,1.7-0.6,2.3-1.3l0,0C45.3,43.3,45.5,42,45,40.8z M7.5,18.2h1l0,5.7l-1.1,0L7.5,18.2z M1.7,32.7l1.2,1.4l-1.1,1.3
				C1.5,34.5,1.5,33.6,1.7,32.7z M12.8,28.8l-1.4-1.5c0.5,0,1,0,1.5,0c0.3,0,0.6,0,1,0l0.1,0.1c-0.3,0.4-0.6,0.8-0.9,1.1
				C13,28.6,12.9,28.7,12.8,28.8L12.8,28.8z M14,32.3l-1.2,1.4l-1.9-2.1l1.2-1.3C12.7,30.9,13.5,31.6,14,32.3L14,32.3z M11.7,29.1
				c-0.5,0.7-1.1,1.5-1.8,2.1c-0.4-0.4-0.9-0.9-1.3-1.3l0-0.1l1.8-1.9L11.7,29.1z M9.6,32.3c-0.3,0.4-0.7,0.8-1,1.2l-0.1,0.1
				l-1.8-2.1l1.1-1.3C8.4,30.9,9,31.6,9.6,32.3z M8.7,28.4c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4-0.3-0.7-0.6-1-1c-0.1-0.1-0.2-0.3-0.3-0.4
				c0.7-0.1,1.3-0.1,2-0.1c0.2,0,0.3,0,0.5,0l0.1,0.1C9.3,27.8,9,28.1,8.7,28.4z M7.4,29.2l-1.8,2h0l-1.3-1.4l1.8-2L7.4,29.2z
				 M5.2,32.2c-0.4,0.5-0.8,1-1.3,1.4c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.5-0.9-0.9-1.3-1.4c0.2-0.3,0.5-0.7,0.8-0.9
				c0.2-0.2,0.3-0.3,0.5-0.5L5.2,32.2z M3.9,28.7l-1.2-1.3c0.8-0.1,1.6-0.1,2.4,0l0,0L3.9,28.7z M3,29.1c-0.3,0.4-0.7,0.7-1,1.1
				l-0.3,0.3l-0.1-2.6l0.1-0.2c0.4,0.3,0.7,0.6,1,1C2.8,28.9,2.9,29,3,29.1L3,29.1z M4.3,36.6c-0.7,0.1-1.4-0.1-2-0.4l0.9-1.1
				c0.5,0.4,0.9,0.9,1.2,1.4c0,0,0.1,0.1,0.1,0.1H4.3z M4.3,34.8c0.4-0.5,0.8-0.9,1.1-1.3c0.3-0.3,0.5-0.5,0.7-0.8
				c0.2,0.2,0.5,0.5,0.7,0.7C7,33.6,7.2,33.8,7.4,34l-1.9,2.1L4.3,34.8z M6.5,36.6C6.5,36.6,6.5,36.6,6.5,36.6l1.2-1.6
				c0.5,0.5,1,1,1.4,1.5L6.5,36.6z M9.2,35.3c-0.2-0.2-0.3-0.4-0.5-0.5c0.6-0.7,1.2-1.4,1.8-2l1.3,1.4L9.9,36
				C9.7,35.8,9.4,35.5,9.2,35.3L9.2,35.3z M11.4,36.6c-0.2,0-0.3,0-0.5,0l0,0c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.3,0.5-0.7,0.8-0.9
				l1.2,1.3C12.7,36.7,12.1,36.7,11.4,36.6L11.4,36.6z M14.1,35.8l-1-1l1.3-1.4C14.5,34.2,14.4,35.1,14.1,35.8L14.1,35.8z
				 M14.4,31.2l-1.2-1.3c0.4-0.5,0.8-1,1.2-1.4V31.2z M14.3,24.9c0.1,0.3,0.1,0.6,0,0.9v0.5H1.7c0-0.1,0-0.1,0-0.2
				c-0.1-0.4-0.1-0.9,0-1.3L14.3,24.9z"
      />
    </g>
  ),
};
export default svg;
